import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="container text-center vh-100 d-flex align-items-center justify-content-center">
      <div>
        <img
          src="./logo.jpg" // Replace with any placeholder or construction image
          alt="Under Construction"
          className="img-fluid mb-4"
          style={{ maxWidth: '40%', height: 'auto' }}
        />
        <h1 className="display-4 text-warning">Under Construction</h1>
        <p className="lead">We're working hard to bring you a better experience. Stay tuned!</p>
      </div>
    </div>
  );
}

export default App;
